import React from 'react';

export const About = () => {
	return (
		<div>
			<h2>ABOUT</h2>
			<p>
				My name is <b>Victor Miguel Ottati Gorsira</b>, I am Venezuelan and
				Italian, and I live in the UK. I am a Civil Engineer, Website Developer
				and Photographer.
			</p>
			<p>
				I started with photography at the age of 17, with a{' '}
				<b>Minolta XG1 SLR</b>. During my life I had several cameras, including
				my <b>NIKON D3300 DSLR</b>. I really like to take pictures with my
				cameras and also with my cell phone, you know that the moment is the key
				in photography and I took several photography courses to improve my
				skills.
			</p>

			<p>
				I do Urban Photography, Artistic Photography, Architectural Photography
				and sometimes Macro Photography and Landscapes.
			</p>
			<p>
				I am currently working on many projects. My three most important
				projects are:
				<i>OLD NEW (Architecture), Street Musician and Art Photography.</i>
			</p>
			<br />
			<p>
				<b>OLD NEW</b> is a project to explore the relationship between old
				buildings and new buildings, sharing the same space in the city,
				creating contrasts, sometimes beautiful and sometimes not so much.
			</p>
			<br />
			<p>
				<b>STREET MUSICIAN </b>is a collection of buskers who play music on the
				streets.The reason is that I love music.
			</p>
			<br />
			<p>
				<b>ART PHOTOGRAPHY</b> because of my relationship with my wife, she is a
				great artist and sculptor. It is is one of my more interesting projects.
			</p>
			<br />
			<p>
				If you dare,{' '}
				<b>
					<i>look at the world through the eyes of a photographer</i>
				</b>
				, then let's enjoy my journey together.
			</p>
		</div>
	);
};
