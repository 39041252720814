export default function validateVideo(values) {
	let errors = {};

	// Type
	if (!values.type) {
		errors.type = 'Video type is required';
	}

	// Title
	if (!values.title) {
		errors.title = 'Video title is required';
	}

	// Link
	if (!values.link) {
		errors.link = 'Video link is required';
	}

	return errors;
}
