import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

// Import hook "useParams" for read route from props
import { useParams } from 'react-router-dom';

// Components
import { NavBar } from '../components/NavBar';
import { GalleryFilter } from '../components/galleries/GalleryFilter';
import GalleryList from '../components/galleries/GalleryList';
import { ScrollBackToTop } from '../components/ScrollBackToTop';
import { Footer } from '../components/Footer';

// Icons
import { ReactComponent as Icon1 } from '../assets/icons/nav/left-arrow.svg';
import { ReactComponent as Icon2 } from '../assets/icons/nav/right-arrow-solid.svg';

// Services
import AuthContextProvider from '../context/AuthContext';
import ProjectsContextProvider from '../context/ProjectsContext';
import PhotoContextProvider from '../context/PhotoContext';

export const Gallery = () => {
	const [dataRender, setDataRender] = useState([]);

	// Services Context
	const { user } = useContext(AuthContextProvider);
	const { dataList } = useContext(ProjectsContextProvider);
	const { photoList, setRefresh } = useContext(PhotoContextProvider);
	// console.log('Projects List: ', dataList);
	// console.log('Photo List: ', photoList);

	// Read route from props
	const { route } = useParams();
	// console.log(route);

	const projectName = route;

	// const dataProject = dataProjects.filter(
	// 	(value) => value.project.toLowerCase() === projectName.toLowerCase()
	// );

	const dataProject = dataList.filter(
		(value) => value.project.toLowerCase() === projectName.toLowerCase()
	);

	// Data
	const project = dataProject[0].project;
	const name = dataProject[0].name;
	const filters = dataProject[0].filters;
	const gallery = dataProject[0].gallery;
	const before = dataProject[0].before;
	const after = dataProject[0].after;

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, []);

	return (
		<div className="general__container_gallery">
			<header>
				<NavBar menuName={'gallery'} photoList={photoList} />
			</header>

			<main>
				<Link to={`${before}`} className="left">
					<Icon1 />
				</Link>
				<Link to={`${after}`} className="right">
					<Icon2 />
				</Link>
				<Link to="/projects" className="title">
					{`Gallery - `}
					<span>{`${name}`}</span>
				</Link>
				<GalleryFilter
					// data={data}
					data={photoList}
					setDataRender={setDataRender}
					project={project}
					filters={filters}
				/>

				{dataList.length >= 1 ? (
					<>
						<GalleryList
							data={dataRender}
							gallery={gallery}
							setRefresh={setRefresh}
						/>
						<ScrollBackToTop />
					</>
				) : (
					<h2 className="loading">Loading PHOTOS ...</h2>
				)}
			</main>

			<footer id="footer">
				<Footer user={user} />
			</footer>
		</div>
	);
};
