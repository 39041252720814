import React, { useState, useContext } from 'react';

// Components
import { VideoDATA } from '../../components/VideosCRUD/VideoDATA';

// Hooks
import useForm from '../../hooks/useForm';
import validate from '../../hooks/validateVideo';

// Services
import { DeleteVideo } from '../../services/deleteData';
import { EditVideo } from '../../services/patchData';

// Services Context
import VideoContextProvider from '../../context/VideoContext';

export const VideoCARD = ({ video, num }) => {
	// console.log(video);
	// Context
	const { setRefresh } = useContext(VideoContextProvider);

	// States
	const [serverError, setServerError] = useState('');
	const [update, setUpdate] = useState(false);

	const initialForm = {
		type: video.type,
		title: video.title,
		link: video.link,
	};

	// Hook
	const { handleChange, handleSubmit, values, errors, reset } = useForm(
		submit,
		validate,
		initialForm
	);

	function submit() {
		handleUpdate();
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}

	const handleUpdate = () => {
		const valuesEdited = {
			videoId: video._id,
			type: values.type,
			title: values.title,
			link: values.link,
		};
		EditVideo({ valuesEdited, setServerError }).then(setRefresh(true));
		setUpdate(false);
	};

	const handleForm = () => {
		setUpdate(true);
	};

	const handleDelete = () => {
		let id = video._id;
		DeleteVideo({ id }).then(setRefresh(true));
	};

	const handleReset = () => {
		setUpdate(false);
	};

	return (
		<div className='card-video-container'>
			{!update ? (
				<VideoDATA
					video={video}
					num={num}
					handleDelete={handleDelete}
					handleForm={handleForm}
				/>
			) : (
				<form
					className='input-video-container'
					onSubmit={handleSubmit}
					noValidate
				>
					<div className='input-video-data'>
						<div className='item-content'>
							<span className='error'>
								{serverError && <p style={{ color: 'red' }}>{serverError}</p>}
							</span>
							<label>Type: </label>
							<input
								id='type'
								className='Item'
								name='type'
								type='text'
								value={values.type}
								onChange={handleChange}
							/>
						</div>
						<span className='error'>
							{errors.type && <p className='error'>{errors.type}</p>}
						</span>
						<div className='item-content'>
							<label>Title: </label>
							<input
								id='title'
								className='Item'
								name='title'
								type='text'
								value={values.title}
								onChange={handleChange}
								placeholder='Add a video title'
							/>
						</div>
						<span className='error'>
							{errors.title && <p className='error'>{errors.title}</p>}
						</span>
						<div className='item-content'>
							<label>Link: </label>
							<input
								id='link'
								className='Item'
								name='link'
								type='text'
								value={values.link}
								onChange={handleChange}
							/>
						</div>
						<span className='error'>
							{errors.link && <p className='error'>{errors.link}</p>}
						</span>
						<div className='input-btn-container'>
							<button className='submit' type='submit'>
								Submit
							</button>
							<button className='reset' onClick={handleReset}>
								Card
							</button>
						</div>
					</div>
				</form>
			)}
		</div>
	);
};
