import Axios from 'axios';

const URL = process.env.REACT_APP_URL;

// DELETE PHOTO by ID
export const DeletePhoto = async ({ id }) => {
	const endpoint = '/api/photo/delete';

	try {
		const data = await Axios.delete(`${URL}${endpoint}/${id}`);

		// console.log(data.data);
		return data.data;
	} catch (error) {
		console.log(error.response.data.error);
	}
	return console.log('id submitted:', id);
};

// DELETE PROJECT by ID
export const DeleteProject = async ({ id }) => {
	const endpoint = '/api/project/delete';

	try {
		const data = await Axios.delete(`${URL}${endpoint}/${id}`);

		console.log(data.data);
		return data.data;
	} catch (error) {
		console.log(error.response.data.error);
	}
	return console.log(`${URL}${endpoint}/${id}`);
};

// DELETE VIDEO by ID
export const DeleteVideo = async ({ id }) => {
	const endpoint = '/api/video/delete';

	try {
		const data = await Axios.delete(`${URL}${endpoint}/${id}`);

		console.log(data.data);
		return data.data;
	} catch (error) {
		console.log(error.response.data.error);
	}
	return console.log(`${URL}${endpoint}/${id}`);
};
