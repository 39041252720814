import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

// Routes
import Home from '../pages/Home';
import { Projects } from '../pages/Projects';
import { Gallery } from '../pages/Gallery';
import { Admin } from '../pages/Admin';
import { AddPhotos } from '../pages/AddPhotos';
import { Photos } from '../pages/Photos';
import { Videos } from '../pages/Videos';
import { PrivacyPolicy } from '../pages/PrivacyPolicy';
import { CookiePolicy } from '../pages/CookiePolicy';
import { ProjectCRUD } from '../pages/ProjectsCRUD';
import { VideosCRUD } from '../pages/VideosCRUD';

// Services
import { AuthContextProvider } from '../context/AuthContext';
import { PhotoContextProvider } from '../context/PhotoContext';
import { ProjectsContextProvider } from '../context/ProjectsContext';
import { VideoContextProvider } from '../context/VideoContext';

export const AppRouter = () => {
	return (
		<BrowserRouter>
			<AuthContextProvider>
				<PhotoContextProvider>
					<ProjectsContextProvider>
						<VideoContextProvider>
							<Routes>
								<Route path='/' element={<Home />} />
								<Route path='/projects' element={<Projects />} />
								<Route path='/gallery/:route' element={<Gallery />} />
								<Route path='/admin' element={<Admin />} />
								<Route path='/addphotos' element={<AddPhotos />} />
								<Route path='/photos' element={<Photos />} />
								<Route path='/videos' element={<Videos />} />
								<Route path='/projects/crud' element={<ProjectCRUD />} />
								<Route path='/videos/crud' element={<VideosCRUD />} />
								<Route
									path='/doc/privacy'
									element={<PrivacyPolicy />}
									rel='nofollow'
								/>
								<Route
									path='/doc/cookies'
									element={<CookiePolicy />}
									rel='nofollow'
								/>
								<Route path='*' element={<Navigate to='/' />} />
							</Routes>
						</VideoContextProvider>
					</ProjectsContextProvider>
				</PhotoContextProvider>
			</AuthContextProvider>
		</BrowserRouter>
	);
};
