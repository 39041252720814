import React, { useState, useContext } from 'react';

// Service
import { DeleteProject } from '../../services/deleteData';

// Services Context
import ProjectsContextProvider from '../../context/ProjectsContext';

// Modals
import { Toast } from '../../components/Toast';

// Components
import { CardINPUT } from './CardINPUT';

export const ProjectCARD = ({ project, num }) => {
	// States
	const [openToast, setOpenToast] = useState(false);
	const [editCard, setEditCard] = useState(false);

	// Context
	const { setRefresh } = useContext(ProjectsContextProvider);

	const handleDelete = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		setOpenToast(true);
	};

	const deleteQuoteDB = async () => {
		// console.log(`Delete: ${project._id}`);
		let id = project._id;
		DeleteProject({ id }).then(setRefresh(true));
		setOpenToast(false);
	};

	const handleUpdate = () => {
		// console.log(`Update: ${project._id}`);
		setEditCard(true);
	};

	return (
		<div className='card-project-container'>
			{openToast && (
				<Toast
					closeIcon={true}
					title={'DELETE'}
					message={'Are you sure you want to delete this quote?'}
					action={'Delete'}
					actionFunction={deleteQuoteDB}
					position={'Center'} // Top, Center or Botton
					setOpenToast={setOpenToast}
					// timeout={5000}
				/>
			)}
			{editCard ? (
				<CardINPUT
					project={project}
					photokey={'img'}
					setEditCard={setEditCard}
				/>
			) : (
				<>
					<div className='card-project-img'>
						<span className='number'>{num + 1}</span>
						<img src={project.img.url} />
					</div>

					<div className='card-project-data'>
						<p>
							Project: <b>{project.project}</b>
						</p>
						<p>
							Name: <b>{project.name}</b>
						</p>
						<p className='card-data-img'>
							IMG: <b>{project.img.url.toString()}</b>
						</p>
						<p>
							Filters:
							{project.filters.map((pro, i) => (
								<b className='filter' key={i}>
									{pro}
								</b>
							))}
						</p>

						<p>
							Gallery: <b>{project.gallery}</b>
						</p>
						<p>
							Pos: <b>{project.pos}</b>
						</p>
						<p>
							Before: <b>{project.before}</b>
						</p>
						<p>
							After: <b>{project.after}</b>
						</p>
						<div className='buttons-container'>
							<button className='btn' onClick={handleUpdate}>
								✏️
							</button>
							<button className='btn' onClick={handleDelete}>
								🗑
							</button>
						</div>
					</div>
				</>
			)}
		</div>
	);
};
