import Axios from 'axios';

const URL = process.env.REACT_APP_URL;

// USERS /////////////////////////////////
// Get User
export const GetUser = async ({ user, handleUserData, setServerError }) => {
	const endpoint = '/api/user/user';

	try {
		const data = await Axios.get(`${URL}${endpoint}/${user}`);

		// console.log(data.data);
		handleUserData(data);
		return data.data;
	} catch (error) {
		console.log(error.response.data.error);
		setServerError(error.response.data.error);
	}
};

// Get Users List
export const GetUsers = async ({ setDataList, setLoading, setError }) => {
	const endpoint = '/api/user/list';
	setLoading(true);
	try {
		const data = await Axios.get(`${URL}${endpoint}`);
		setDataList(data.data.Users);
		setLoading(false);
		return data.data;
	} catch (error) {
		console.log(error.response.data.error);
		setError(error.response.data.error);
	}
};

// PHOTOS ///////////////////////////////////
// Get Photo List
export const GetPhotos = async ({ setPhotoList, setLoading, setError }) => {
	const endpoint = '/api/photo/list';
	setLoading(true);
	try {
		const data = await Axios.get(`${URL}${endpoint}`);
		setPhotoList(data.data.photos);
		setLoading(false);
		return data.data;
	} catch (error) {
		console.log(error.response.data.error);
		setError(error.response.data.error);
	}
};

// PROJECTS ////////////////////////////////////////////
// Get Projects List
export const GetProjects = async ({ setDataList, setLoading, setError }) => {
	const endpoint = '/api/project/list';
	setLoading(true);
	try {
		const data = await Axios.get(`${URL}${endpoint}`);
		setDataList(data.data.projects);
		setLoading(false);
		return data.data;
	} catch (error) {
		console.log(error.response.data.error);
		setError(error.response.data.error);
	}
};

// VIDEOS ///////////////////////////////////
// Get Video List
export const GetVideos = async ({ setVideoList, setLoading, setError }) => {
	const endpoint = '/api/video/list';
	setLoading(true);
	try {
		const data = await Axios.get(`${URL}${endpoint}`);
		setVideoList(data.data.videos);
		setLoading(false);
		return data.data;
	} catch (error) {
		console.log(error.response.data.error);
		setError(error.response.data.error);
	}
};
