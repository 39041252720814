import { useEffect, useState } from 'react';

// Library
import exifr from 'exifr'; // => exifr/dist/full.umd.cjs

export const ExifData = ({ img, i }) => {
	const [data, setData] = useState({});

	// exifr reads the file.
	useEffect(() => {
		exifr.parse(img, { xmp: true }).then((output) => setData(output));
	}, [img]);

	return (
		data.title && {
			num: i,
			src: img,
			Title: data.title.value || '',
			Caption: data.ImageDescription,
			Keywords: data.subject,
			Make: data.Make,
			Model: data.Model,
			Lens: data.Lens,
			f: data.FNumber,
			s: Math.trunc(1 / data.ExposureTime),
			ISO: data.ISO,
			Focal: data.FocalLength,
			Mode: data.ExposureMode,
			Copyright: data.Copyright,
			Created: data.CreateDate,
		}
	);
};
