import React from "react";
import { Link } from "react-router-dom";

// helper
import { year } from "../helpers/Date";
import { clearLocalStoreValue } from "../helpers/LocalStore";

// Icons
import { ReactComponent as FacebookIcon } from "../assets/icons/social/facebook.svg";
import { ReactComponent as InstagramIcon } from "../assets/icons/social/instagram.svg";
import { ReactComponent as PrinterIcon } from "../assets/icons/social/3d-printer.svg";
import { ReactComponent as EmailIcon } from "../assets/icons/social/email.svg";
import { ReactComponent as PhoneIcon } from "../assets/icons/social/phone-call.svg";
import { ReactComponent as LinkedinIcon } from "../assets/icons/social/linkedin.svg";
import { ReactComponent as GithubIcon } from "../assets/icons/social/github.svg";
import { ReactComponent as IiktokIcon } from "../assets/icons/social/tiktok.svg";

// Read JSON Data
const geninfo = require("../data/geninfo.json");
const geninfodata = geninfo.data;

export const Footer = ({
  user,
  setOpenLogin,
  setUser,
  setAvatar,
  setUserAdmin,
  setUserAcceptCookies,
}) => {
  // Modal function
  const handleClick = () => {
    let modal = document.querySelector(".modal");
    let email = geninfodata[0].email;
    modal.classList.remove("hidden");
    document.querySelector("#emailhref").href = email;
    setTimeout(function () {
      modal.classList.add("hidden");
      document.querySelector("#emailhref").href = "";
    }, 5000);
  };

  const handleLogin = () => {
    setOpenLogin(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleLogout = () => {
    setUser("");
    setAvatar("");
    setUserAdmin(false);
    setUserAcceptCookies(false);
    clearLocalStoreValue("auth-ID");
  };

  return (
    <div className="footer">
      <div className="footer__open"></div>
      <div className="modal hidden">
        <span>Please Confirm:</span>
        <a
          href="fake@email.null"
          target="_blank"
          rel="noreferrer"
          id="emailhref"
        >
          <p>☑️ I'm not a robot 🤖 </p>
        </a>
      </div>
      <div className="svc__icon footer-icon">
        <a href={geninfodata[0].facebook} target="_blank" rel="noreferrer">
          <FacebookIcon/>
        </a>
        <a href={geninfodata[0].instagram} target="_blank" rel="noreferrer">
          <InstagramIcon />
        </a>
        <a href={geninfodata[0].linkedin} target="_blank" rel="noreferrer">
          <LinkedinIcon />
        </a>
        <a href={geninfodata[0].github} target="_blank" rel="noreferrer">
          <GithubIcon />
        </a>
        <a href={geninfodata[0].tiktok} target="_blank" rel="noreferrer">
          <IiktokIcon />
        </a>
        <a href={geninfodata[0].printer} target="_blank" rel="noreferrer">
          <PrinterIcon />
        </a>
        <a href={geninfodata[0].phonecall} target="_blank" rel="noreferrer">
          <PhoneIcon />
        </a>
        <p onClick={handleClick} id="email">
          <EmailIcon />
        </p>
        {!user ? (
          <p onClick={handleLogin} id="email">
            Login
          </p>
        ) : (
          <p onClick={handleLogout} id="email">
            Logout
          </p>
        )}
      </div>
      <div className="copywrite">
        <p className="linked">
          <span className="policy-text">All Right Reserved</span>

          <Link to="/doc/privacy" className="policy">
            PRIVACY POLICY
          </Link>

          <Link to="/doc/cookies" className="policy">
            COOKIE POLICY
          </Link>
        </p>

        <p>
          <a href={geninfodata[0].webpage} target="_blank" rel="noreferrer">
            Copyright and web design by © VMOG
          </a>
        </p>
        <p> Liverpool UK {year} </p>
      </div>
    </div>
  );
};
