import React, { useEffect, useContext, useState } from 'react';
import { NavBar } from '../components/NavBar';
import { Navigate } from 'react-router-dom';

// Pages
import Home from '../pages/Home';

// Modals
import { Toast } from '../components/Toast';

// Components
import { VideoINPUT } from '../components/VideosCRUD/VideoINPUT';

// Services Context
import AuthContextProvider from '../context/AuthContext';
import VideoContextProvider from '../context/VideoContext';
import { VideoCARD } from '../components/VideosCRUD/VideoCARD';

export const VideosCRUD = () => {
	// Services Context
	const { user } = useContext(AuthContextProvider);
	const { videoList } = useContext(VideoContextProvider);

	// States
	const [openToast, setOpenToast] = useState(false);

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		// console.log(dataList);
	}, []);

	if (!user) {
		return <Navigate to='/' element={<Home />} />;
	}

	return (
		<div className='general__container admin'>
			{openToast && (
				<Toast
					closeIcon={true}
					title={'VIDEO'}
					message={'Video added successfully'}
					action={'text'}
					// actionFunction={setOpenToast}
					position={'Center'} // Top, Center or Botton
					setOpenToast={setOpenToast}
					timeout={3000}
				/>
			)}
			<header>
				<NavBar menuName='dashboard' />
				<h2 className='title'>Videos CRUD</h2>
			</header>
			<main>
				<div
					className='general__container projects-container'
					style={{ padding: '1em' }}
				>
					<h2 className='crud-title'>Add New Video</h2>
					<VideoINPUT setOpenToast={setOpenToast} />
					<br />

					<h2 className='crud-title'>
						Videos: <b>{videoList.length}</b>
					</h2>

					<div className='crud-container'>
						{videoList.map((video, i) => (
							<VideoCARD video={video} key={video._id} num={i} />
						))}
					</div>
				</div>
			</main>
			<footer>
				<p>
					<a href='https://vmog.net/' target='_blank' rel='noreferrer'>
						Copyright and web design by © VMOG
					</a>
				</p>
				<p> Liverpool UK 2022 </p>
			</footer>
		</div>
	);
};
