import React, { useEffect, useState } from 'react';

export const GalleryFilter = ({ data, setDataRender, project, filters }) => {
	const [dataProject, setDataProject] = useState([]);

	// Set data filtered for this project (Gallery)
	useEffect(() => {
		// console.log(data);
		// console.log(project);
		// console.log(filters);

		const projectData = data.filter((value) => {
			return value.keywords.toLowerCase().includes(project.toLowerCase());
		});
		// console.log(projectData);
		setDataRender(projectData); // Data for initial rendering
		setDataProject(projectData); // Data for this gallery (project)
	}, [data, project, setDataRender]);

	// Filter data using exif keywords
	const handleFilter = (filterWord) => {
		const newData = dataProject.filter((value) => {
			return value.keywords.toLowerCase().includes(filterWord.toLowerCase());
		});
		setDataRender(newData);
	};

	return (
		<div className='search-container'>
			{
				<button
					className='btn btn__mobile'
					onClick={() => setDataRender(dataProject)}
				>
					All
				</button>
			}

			{filters.map((filter, i) => {
				return (
					<button
						key={i}
						className='btn btn__mobile'
						onClick={() => handleFilter(`${filter}`)}
					>
						{filter}
					</button>
				);
			})}
		</div>
	);
};
