import React, { useEffect, useContext, useState } from 'react';
import { NavBar } from '../components/NavBar';
import { Navigate } from 'react-router-dom';

// Pages
import Home from '../pages/Home';

// Modals
import { Toast } from '../components/Toast';

// Components
import { ProjectINPUT } from '../components/ProjectsCRUD/ProjectINPUT';
import { ProjectCARD } from '../components/ProjectsCRUD/ProjectCARD';

// Services Context
import AuthContextProvider from '../context/AuthContext';
import ProjectsContextProvider from '../context/ProjectsContext';

export const ProjectCRUD = () => {
	// Services Context
	const { user } = useContext(AuthContextProvider);
	const { dataList } = useContext(ProjectsContextProvider);

	// States
	const [search, setSearch] = useState('');
	const [openToast, setOpenToast] = useState(false);

	// SEARCH FUNCTION
	const searcher = (e) => {
		setSearch(e.target.value);
		//console.log(e.target.value)
	};

	// FILTER FUNCTION
	let results = !search
		? dataList
		: dataList.filter((data) =>
				data.project.toLowerCase().startsWith(search.toLowerCase())
		  );
	// console.log(results);

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		// console.log(dataList);
	}, []);

	if (!user) {
		return <Navigate to='/' element={<Home />} />;
	}

	return (
		<div className='general__container admin'>
			{openToast && (
				<Toast
					closeIcon={true}
					title={'PROJECT'}
					message={'Project added successfully'}
					action={'text'}
					// actionFunction={setOpenToast}
					position={'Center'} // Top, Center or Botton
					setOpenToast={setOpenToast}
					timeout={3000}
				/>
			)}
			<header>
				<NavBar menuName='dashboard' />
				<h2 className='title'>Projects CRUD</h2>
			</header>

			<main>
				<div
					className='general__container projects-container'
					style={{ padding: '1em' }}
				>
					<h2 className='crud-title'>Add New Project</h2>
					<ProjectINPUT photokey={'img'} setOpenToast={setOpenToast} />
					<hr />

					<h2 className='crud-title'>
						Projects List: <b> {`${dataList.length}`}</b>
					</h2>
					<div className='search-container'>
						<input
							value={search}
							onChange={searcher}
							type='text'
							placeholder='Search...'
							className='search'
						/>
						{search && (
							<button className='btn' onClick={() => setSearch('')}>
								🗑
							</button>
						)}
					</div>
					{results.map((project, i) => (
						<ProjectCARD project={project} key={project._id} num={i} />
					))}
				</div>
			</main>

			<footer>
				<p>
					<a href='https://vmog.net/' target='_blank' rel='noreferrer'>
						Copyright and web design by © VMOG
					</a>
				</p>
				<p> Liverpool UK 2022 </p>
			</footer>
		</div>
	);
};
