import Axios from 'axios';

const URL = process.env.REACT_APP_URL;

// UPDATE USER PROFILE
export const EditUser = async ({ valuesEdited, setServerError }) => {
	// console.log(valuesEdited);
	const endpoint = '/api/user/edit';
	const config = {
		header: {
			'Content-Type': 'application/json',
		},
	};

	try {
		const data = await Axios.patch(
			`${URL}${endpoint}`,
			{
				userId: valuesEdited.userId,
				updateData: valuesEdited.updateData,
			},
			config
		);

		// console.log(data.data);
		return data.data;
	} catch (error) {
		console.log(error.response.data.error);
		setServerError(error.response.data.error);
		setTimeout(() => {
			setServerError('');
		}, 5000);
	}
};

// UPDATE PROJECT
export const EditProject = async ({ valuesEdited, setServerError }) => {
	// console.log(`projectId: ${valuesEdited.projectId}`);

	const endpoint = '/api/project/update';
	const config = {
		header: {
			'Content-Type': 'application/json',
		},
	};

	try {
		const data = await Axios.patch(
			`${URL}${endpoint}`,
			{
				projectId: valuesEdited.projectId,
				updateData: valuesEdited.updateData,
			},
			config
		);

		// console.log(data.data);
		return data.data;
	} catch (error) {
		console.log(error.response.data.error);
		setServerError(error.response.data.error);
		setTimeout(() => {
			setServerError('');
		}, 5000);
	}
};

// UPDATE PHOTO
export const EditPhoto = async ({ valuesEdited, setServerError }) => {
	// console.log(`photoId: ${valuesEdited.photoId}`);

	const endpoint = '/api/photo/edit';
	const config = {
		header: {
			'Content-Type': 'application/json',
		},
	};

	try {
		const data = await Axios.patch(
			`${URL}${endpoint}`,
			{
				photoId: valuesEdited.photoId,
				updateData: valuesEdited.updateData,
			},
			config
		);

		// console.log(data.data);
		return data.data;
	} catch (error) {
		console.log(error.response.data.error);
		setServerError(error.response.data.error);
		setTimeout(() => {
			setServerError('');
		}, 5000);
	}
};

// UPDATE OR EDIT VIDEO
export const EditVideo = async ({ valuesEdited, setServerError }) => {
	// console.log(`photoId: ${valuesEdited.photoId}`);
	// console.log(valuesEdited);

	const endpoint = '/api/video/update';
	const config = {
		header: {
			'Content-Type': 'application/json',
		},
	};

	try {
		const data = await Axios.patch(
			`${URL}${endpoint}`,
			{
				videoId: valuesEdited.videoId,
				type: valuesEdited.type,
				title: valuesEdited.title,
				link: valuesEdited.link,
			},
			config
		);

		// console.log(data.data);
		return data.data;
	} catch (error) {
		console.log(error.response.data.error);
		setServerError(error.response.data.error);
		setTimeout(() => {
			setServerError('');
		}, 5000);
	}
};
