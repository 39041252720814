import React from 'react';

// Helper
import { ExifData } from '../helpers/ExifData';

function PhotosToJson({ imgFolder, imgName, nPhotos }) {
	const getData = () => {
		let photos = [];
		for (let i = 1; i <= nPhotos; i++) {
			let img = `${imgFolder}${imgName}${i}.jpg`;
			let data = ExifData({ img, i });
			photos.push(data);
		}
		return photos;
	};

	const photosExif = JSON.stringify(getData(), null, '\t');

	const handleDownload = () => {
		var element = document.createElement('a');
		element.setAttribute(
			'href',
			'data:text/plain;charset=utf-8,' + encodeURIComponent(photosExif)
		);
		element.setAttribute('download', 'photos.json');

		element.style.display = 'none';
		document.body.appendChild(element);

		element.click();

		document.body.removeChild(element);
	};

	return (
		<div className='photos-to-json '>
			<div className='title'>
				<span>Photos to JSON</span>
			</div>
			<p>{photosExif}</p>
			<button className='btn btn__click btn__photos' onClick={handleDownload}>
				<span>Download</span>
			</button>
		</div>
	);
}

export default PhotosToJson;
