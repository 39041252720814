import React, { useState, useEffect, useContext } from 'react';

// Components
import { NavBar } from '../components/NavBar';
import GalleryList from '../components/galleries/GalleryList';
import { ScrollBackToTop } from '../components/ScrollBackToTop';
import { RotatingLines } from 'react-loader-spinner';
import { Footer } from '../components/Footer';

// Services
import AuthContextProvider from '../context/AuthContext';
import PhotoContextProvider from '../context/PhotoContext';

// JSON Data
const initialPhotos = require('../data/photos.json');

export const Photos = () => {
	const [dataRender, setDataRender] = useState([]);
	const [search, setSearch] = useState('');
	const [btnReset, setBtnReset] = useState(false);

	// Services Context
	const { user } = useContext(AuthContextProvider);
	const { photoList, setRefresh } = useContext(PhotoContextProvider);
	// console.log('Photo List: ', photoList);

	// SEARCH FUNCTION
	const searcher = (e) => {
		setSearch(e.target.value);
		//console.log(e.target.value)
	};

	// FILTER data using exif keywords
	const handleFilter = (filterWord) => {
		const newData = photoList.filter((value) => {
			return value.keywords.toLowerCase().includes(search.toLowerCase());
		});
		setDataRender(newData);
		setBtnReset(true);
		// console.log(newData);
	};

	const Reset = () => {
		setSearch('');
		setDataRender(photoList);
		setBtnReset(false);
	};

	useEffect(() => {
		// console.log(initialPhotos);
		setDataRender(photoList);
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, []);

	return (
		<div className="general__container_gallery">
			<header>
				<NavBar menuName={'photos'} photoList={photoList} />
			</header>

			<main>
				<h1 className="title-photos">PHOTOS</h1>

				<div className="filter-container">
					<input
						value={search}
						onChange={searcher}
						type="text"
						placeholder="Keyword..."
						className="search"
					/>
					{btnReset ? (
						<button className="btn" onClick={Reset}>
							Reset
						</button>
					) : (
						<button className="btn" onClick={handleFilter}>
							Filter
						</button>
					)}

					<p>
						Photos: <b>{dataRender.length}</b>
					</p>
				</div>
				{photoList.length < 10 && (
					<>
						<GalleryList
							data={initialPhotos}
							gallery="mondrian"
							setRefresh={setRefresh}
						/>
						<div className="Loading">
							<h2>Loading...</h2>
							<br />
							<RotatingLines
								strokeColor="#007AFF"
								strokeWidth="5"
								animationDuration="0.75"
								width="96"
								visible={true}
							/>
						</div>
					</>
				)}
				{photoList.length >= 1 && (
					<>
						<GalleryList
							data={dataRender}
							gallery="mondrian"
							setRefresh={setRefresh}
						/>
						<ScrollBackToTop />
					</>
				)}
			</main>

			<footer id="footer">
				<Footer user={user} />
			</footer>
		</div>
	);
};
