import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const GalleryImage = ({
	image,
	scrollPosition,
	modal,
	setModal,
	setModalImage,
	setModalData,
}) => {
	// console.log(image);

	const handleModal = () => {
		// setModalImage(`../assets/images-modal/image-${image.num}.jpg`);
		setModalImage(`${image.image.url}`);
		setModal(!modal);
		setModalData(image);
	};
	return (
		<LazyLoadImage
			className="image-item-container"
			alt={image.title}
			scrollPosition={scrollPosition}
			// src={`../${image.src}`}
			src={`${image.image.url}`}
			onClick={handleModal}
		/>
	);
};
