import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';

// Components
import { NavBar } from '../components/NavBar';
import { Footer } from '../components/Footer';
import { About } from '../components/About';
import { ScrollBackToTop } from '../components/ScrollBackToTop';
import { LoadingComponent } from '../components/Loading/Loading';

// Modals
import { Modal } from '../components/Modal';
import { Share } from '../components/Share';
import LoginForm from '../components/LoginForm';

// Services
import AuthContextProvider from '../context/AuthContext';
import PhotoContextProvider from '../context/PhotoContext';

// Helpers
import { getLocalStoreValue } from '../helpers/LocalStore';

// JSON Data
const landing = require('../data/landing.json');

const Home = () => {
	const [backImage, setBackImage] = useState(0);

	// Open states
	const [openLogin, setOpenLogin] = useState(false);
	const [openShare, setOpenShare] = useState(false);

	// Services Context
	const {
		user,
		setUser,
		setLiked,
		setAvatar,
		setUserAdmin,
		// userAcceptCookies,
		setUserAcceptCookies,
	} = useContext(AuthContextProvider);

	const { photoList } = useContext(PhotoContextProvider);
	// console.log('Photo List: ', photoList);

	useEffect(() => {
		let img = Math.floor(Math.random() * 5);
		img === backImage && (img += 1);
		img > 5 && (img = 1);
		setBackImage(img);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		// Helper Local Storage
		const getUserId = getLocalStoreValue('auth-ID');
		if (getUserId) {
			setUser(getUserId);
			setUserAcceptCookies(true);
			// console.log(user);
		}
	}, [setUser, setUserAcceptCookies]);

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, []);

	return (
		<div className="general__container home">
			{openLogin && (
				<Modal>
					<LoginForm
						setUser={setUser}
						setAvatar={setAvatar}
						setLiked={setLiked}
						setUserAdmin={setUserAdmin}
						setOpenLogin={setOpenLogin}
						// setOpenUserRegisterForm={setOpenUserRegisterForm}
						setUserAcceptCookies={setUserAcceptCookies}
					/>
				</Modal>
			)}

			{openShare && (
				<Share setOpenShare={setOpenShare} url={'photo.vmog.net/'} />
			)}

			<header>
				<NavBar menuName="home" user={user} photoList={photoList} />
			</header>
			<main>
				<div
					className="container-home"
					id="home"
					style={{
						backgroundImage: `url(${landing[backImage].img})`,
						backgroundPositionX: `${landing[backImage].pos}`,
					}}
				>
					<div className="container-home__text">
						<h2>
							VMOG - <span>PHOTOGRAPHY</span>
						</h2>

						<h1>
							<i>Engineer, photographer and website developer</i>
						</h1>

						{photoList?.length <= 10 ? (
							// <p>Loading...</p>
							<LoadingComponent />
						) : (
							<Link to="/projects" style={{ textDecoration: 'none' }}>
								<p>Go to my projects...</p>
							</Link>
						)}
					</div>
				</div>

				<div className="container-about">
					<About />
				</div>
			</main>
			<ScrollBackToTop />
			<footer id="footer">
				<Footer
					user={user}
					setUser={setUser}
					setAvatar={setAvatar}
					setOpenLogin={setOpenLogin}
					setUserAdmin={setUserAdmin}
					setUserAcceptCookies={setUserAcceptCookies}
				/>
			</footer>
		</div>
	);
};

export default Home;
