import React, { useState, useContext } from 'react';

// Hooks
import useForm from '../../hooks/useForm';
import validate from '../../hooks/validateVideo';

// Services
import { PostVideo } from '../../services/postData';

// Services Context
import VideoContextProvider from '../../context/VideoContext';

export const VideoINPUT = (setOpenToast) => {
	// Services Context
	const { setRefresh } = useContext(VideoContextProvider);

	// States
	const [serverError, setServerError] = useState('');

	const initialForm = {
		type: 'tiktok',
		title: '',
		link: 'https://www.tiktok.com/embed/',
	};

	// Hook
	const { handleChange, handleSubmit, values, errors, reset } = useForm(
		submit,
		validate,
		initialForm
	);

	function submit() {
		handleUpdate();
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}

	const handleUpdate = async () => {
		PostVideo({ values, handleData, setServerError }).then(setRefresh(true));
		setOpenToast(true);
	};

	const handleData = async (data) => {
		reset();
		setServerError('');
	};

	const handleReset = () => {
		reset();
	};

	return (
		<form className='input-video-container' onSubmit={handleSubmit} noValidate>
			<div className='input-video-data'>
				<div className='item-content'>
					<span className='error'>
						{serverError && <p style={{ color: 'red' }}>{serverError}</p>}
					</span>
					<label>Type: </label>
					<input
						id='type'
						className='Item'
						name='type'
						type='text'
						value={values.type}
						onChange={handleChange}
					/>
				</div>
				<span className='error'>
					{errors.type && <p className='error'>{errors.type}</p>}
				</span>
				<div className='item-content'>
					<label>Title: </label>
					<input
						id='title'
						className='Item'
						name='title'
						type='text'
						value={values.title}
						onChange={handleChange}
						placeholder='Add a video title'
					/>
				</div>
				<span className='error'>
					{errors.title && <p className='error'>{errors.title}</p>}
				</span>
				<div className='item-content'>
					<label>Link: </label>
					<input
						id='link'
						className='Item'
						name='link'
						type='text'
						value={values.link}
						onChange={handleChange}
					/>
				</div>
				<span className='error'>
					{errors.link && <p className='error'>{errors.link}</p>}
				</span>
				<div className='input-btn-container'>
					<button className='submit' type='submit'>
						Submit
					</button>
					<button className='reset' onClick={handleReset}>
						Reset
					</button>
				</div>
			</div>
		</form>
	);
};
