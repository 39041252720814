import React, { useState, useEffect, useContext } from 'react';

// Components
import { NavBar } from '../components/NavBar';
import { Footer } from '../components/Footer';
import { Resize } from '../components/Resize';

// Services Context
import AuthContextProvider from '../context/AuthContext';
import PhotoContextProvider from '../context/PhotoContext';

// Modals
import { Modal } from '../components/Modal';
import AddPhotoForm from '../components/AddPhotoForm';

export const AddPhotos = () => {
	const [imageBase64, setImageBase64] = useState(null); // Image Base64
	const [imageName, setImageName] = useState(null); // Image name
	const [dataValue, setDataValue] = useState('');
	const [imageSize, setImageSize] = useState(1); // Image size KB
	const [created, setCreated] = useState(null); // Created Date

	// Open states
	const [openAddPhotoForm, setOpenAddPhotoForm] = useState(false);

	// Services Context
	const { user } = useContext(AuthContextProvider);
	const { setRefresh } = useContext(PhotoContextProvider);

	const handleUpdate = () => {
		// Update dataList
		setRefresh(true);
	};

	const handleRegister = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		setOpenAddPhotoForm(true);
	};

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, []);

	return (
		<div className='general__container_photos'>
			{openAddPhotoForm && (
				<Modal>
					<AddPhotoForm
						setOpenAddPhotoForm={setOpenAddPhotoForm}
						imageSize={imageSize}
						imageBase64={imageBase64}
						imageName={imageName}
						dataValue={dataValue}
						created={created}
					/>
				</Modal>
			)}

			<header>
				<NavBar menuName='photos' />
			</header>
			<main>
				<h1 className='title'>Photos</h1>

				<button className='btn btn__mobile' onClick={handleUpdate}>
					Update DB
				</button>
				<br />
				<br />

				<Resize
					setOpenAddPhotoForm={setOpenAddPhotoForm}
					setImageBase64={setImageBase64}
					setImageName={setImageName}
					setImageSize={setImageSize}
					setDataValue={setDataValue}
					setCreated={setCreated}
					imageSize={imageSize}
					imageName={imageName}
					dataValue={dataValue}
					created={created}
				/>
				{/* <br /> */}

				{imageName && (
					<button className='btn-register' onClick={handleRegister}>
						Register Photo
					</button>
				)}
			</main>
			<footer>
				<Footer user={user} />
			</footer>
		</div>
	);
};
