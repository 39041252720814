import React, { useState, useEffect, useContext } from 'react';

// Components
import { NavBar } from '../components/NavBar';
import { RotatingLines } from 'react-loader-spinner';
import { ScrollBackToTop } from '../components/ScrollBackToTop';
import { Footer } from '../components/Footer';

// Services
import AuthContextProvider from '../context/AuthContext';
import VideoContextProvider from '../context/VideoContext';
import PhotoContextProvider from '../context/PhotoContext';

export const Videos = () => {
	const [dataRender, setDataRender] = useState([]);

	// Services Context
	const { user } = useContext(AuthContextProvider);
	const { videoList } = useContext(VideoContextProvider);
	const { photoList } = useContext(PhotoContextProvider);
	// console.log(videoList);

	useEffect(() => {
		setDataRender('');
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, []);

	return (
		<div className="general__container_videos">
			<header>
				<NavBar menuName={'videos'} photoList={photoList} />
			</header>

			<main>
				<h1 className="title">
					VIDEOS: <b>{videoList.length}</b>
				</h1>

				{videoList.length < 3 && (
					<div className="Loading">
						<h2>Loading...</h2>
						<br />
						<RotatingLines
							strokeColor="#007AFF"
							strokeWidth="5"
							animationDuration="0.75"
							width="96"
							visible={true}
						/>
					</div>
				)}
				<div className="videos-container">
					{videoList.map((video) => (
						<iframe
							key={video._id}
							src={video.link}
							allowFullScreen
							scrolling="no"
							allow="encrypted-media;"
							allowtransparency="true"
						></iframe>
					))}
				</div>
				<ScrollBackToTop />
			</main>

			<footer id="footer">
				<Footer user={user} />
			</footer>
		</div>
	);
};
