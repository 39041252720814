import React, { useState } from 'react';

// Hooks
import useForm from '../hooks/useForm';
import validate from '../hooks/validatePhoto';

// Services
import { PostPhotos } from '../services/postData';

// Icons
import { ReactComponent as Close } from '../assets/icons/ui/close_nc.svg';

const AddPhotoForm = ({
	setOpenAddPhotoForm,
	imageBase64,
	imageName,
	imageSize,
	dataValue,
	created,
}) => {
	const initialForm = {
		image: imageBase64,
		imagename: imageName,
		imagesize: imageSize,
		title: dataValue.Title,
		keywords: dataValue.subject,
		make: dataValue.Make,
		model: dataValue.Model,
		lens: dataValue.Lens,
		f: dataValue.FNumber,
		s: dataValue.ExposureTime,
		iso: dataValue.ISO,
		focal: dataValue.FocalLength,
		mode: dataValue.ExposureMode,
		country: '',
		city: '',
		copyright: 'Victor Ottati',
		created: created,
	};

	const [serverError, setServerError] = useState('');

	const { handleChange, handleSubmit, values, errors, reset } = useForm(
		submit,
		validate,
		initialForm
	);

	function submit() {
		PostPhotos({ values, handleData, setServerError });
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}

	const handleData = async (data) => {
		reset();
		setServerError('');
		setOpenAddPhotoForm(false);
		alert('SUCCESSFULLY REGISTERED');
		// await setUser(data.data.user);
	};

	const closeUserRegisterForm = () => {
		setOpenAddPhotoForm(false);
	};

	return (
		<form className='elements_form' onSubmit={handleSubmit} noValidate>
			<span className='icon-close' onClick={closeUserRegisterForm}>
				<Close />
			</span>
			<h2>Photo Register</h2>

			<div className='form-item'>
				<img className='img-form' src={imageBase64} alt={imageName} />
			</div>

			<div className='form-item-data'>
				<p>
					Name: <b>{values.imagename}</b>
				</p>
				<p>
					Size: <b>{values.imagesize}KB</b>
				</p>
				{values.imagesize < 300 ? (
					<p style={{ color: 'green' }}>OK</p>
				) : (
					<p style={{ color: 'red' }}>Check: max size 300KB</p>
				)}
			</div>
			<span className='error'>
				{errors.imagesize && <p className='error'>{errors.imagesize}</p>}
			</span>
			<span className='error'>
				{serverError && <p style={{ color: 'red' }}>{serverError}</p>}
			</span>

			<div className='form-item'>
				<label>Title: (Optional)</label>
				<div>
					<input
						id='title'
						className={`${errors.title && 'inputError'}`}
						name='title'
						type='text'
						value={values.title}
						onChange={handleChange}
					/>
					{errors.title && <p className='error'>{errors.title}</p>}
				</div>
			</div>

			<div className='form-item'>
				<label>Keywords: (*)</label>
				<div>
					<textarea
						id='keywords'
						className={`${errors.keywords && 'inputError'}`}
						rows='4'
						name='keywords'
						type='text'
						value={values.keywords}
						onChange={handleChange}
					/>
					{errors.keywords && <p className='error'>{errors.keywords}</p>}
				</div>
			</div>

			<div className='form-item'>
				<label>City: (*)</label>
				<div>
					<input
						id='city'
						className={`${errors.city && 'inputError'}`}
						name='city'
						type='text'
						value={values.city}
						onChange={handleChange}
					/>
					{errors.city && <p className='error'>{errors.city}</p>}
				</div>
			</div>

			<div className='form-item'>
				<label>Country: (*)</label>
				<div>
					<input
						id='country'
						className={`${errors.country && 'inputError'}`}
						name='country'
						type='text'
						value={values.country}
						onChange={handleChange}
					/>
					{errors.country && <p className='error'>{errors.country}</p>}
				</div>
			</div>

			<div className='form-item-data'>
				<p>
					Camera: <b>{values.make}</b>
				</p>
				<p>
					Model: <b>{values.model}</b>
				</p>
				<p>
					Lens: <b>{values.lens}</b>
				</p>
				<p>
					f: <b>{values.f}</b>
				</p>
				<p>
					S:
					<b>
						1/
						{Math.trunc(1 / values.s)}
					</b>
				</p>
				<p>
					ISO: <b>{values.iso}</b>
				</p>
				<p>
					Focal: <b>{values.focal}</b>
					mm
				</p>
				<p>
					Mode: <b>{values.mode}</b>
				</p>
				<p>
					Copyright: <b>{values.copyright}</b>
				</p>
			</div>

			<div className='form-item'>
				<label>Created: (Mon Dec 09 2022)</label>
				<div>
					<input
						id='created'
						className={`${errors.created && 'inputError'}`}
						name='created'
						type='text'
						value={values.created}
						onChange={handleChange}
					/>
					{errors.created && <p className='error'>{errors.created}</p>}
				</div>
			</div>

			<p style={{ color: 'red' }}>* Required</p>

			<div className='btn-container'>
				<button type='submit' className='btn'>
					Submit
				</button>
			</div>
		</form>
	);
};

export default AddPhotoForm;
