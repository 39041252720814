export default function validateProject(values) {
	let errors = {};

	// Project
	if (!values.project) {
		errors.project = 'Project name is required';
	}

	// Name
	if (!values.name) {
		errors.name = 'Title name is required';
	}

	// Gallery type
	if (!values.gallery) {
		errors.gallery = 'Gallery type is required - default flex';
	}
	// POS
	if (!values.pos) {
		errors.pos = 'Position is required - default center';
	}
	// Before PAGE link
	if (!values.before) {
		errors.before = 'Page link before is required - /gallery/PROY';
	}
	// After PAGE link
	if (!values.after) {
		errors.after = 'Page link after is required - /gallery/PROY';
	}

	return errors;
}
