import Axios from 'axios';

// helpers
import { setLocalStoreValue } from '../helpers/LocalStore';

const URL = process.env.REACT_APP_URL;
// const URL = 'http://localhost:3001';

// USERS
export const PostUser = async ({ values, handleData, setServerError }) => {
	const endpoint = '/api/user/register';
	const config = {
		header: {
			'Content-Type': 'application/json',
		},
	};

	try {
		const data = await Axios.post(
			`${URL}${endpoint}`,
			{
				username: values.name,
				nickname: values.nickname,
				email: values.email,
				photo: values.photo,
				password: values.password,
				subscribe: values.subscribe,
			},
			config
		);

		// console.log(data.data);
		setLocalStoreValue('auth-ID', data.data.user);
		return handleData(data);
	} catch (error) {
		console.log(error.response.data.error);
		setServerError(error.response.data.error);
		setTimeout(() => {
			setServerError('');
		}, 5000);
	}
};

// LOGIN
export const PostLogin = async ({ values, handleData, setServerError }) => {
	const endpoint = '/api/user/login';
	const config = {
		header: {
			'Content-Type': 'application/json',
		},
	};

	try {
		const data = await Axios.post(
			`${URL}${endpoint}`,
			{
				email: values.email,
				password: values.password,
			},
			config
		);

		// console.log(data.data);
		setLocalStoreValue('auth-ID', data.data.user);
		return handleData(data);
	} catch (error) {
		console.log(error.response.data.error);
		setServerError(error.response.data.error);
		setTimeout(() => {
			setServerError('');
		}, 5000);
	}
};

// PHOTOS
export const PostPhotos = async ({ values, handleData, setServerError }) => {
	const endpoint = '/api/photo/register';
	const config = {
		header: {
			'Content-Type': 'application/json',
		},
	};

	try {
		const data = await Axios.post(
			`${URL}${endpoint}`,
			{
				image: values.image,
				imagename: values.imagename,
				title: values.title,
				keywords: values.keywords,
				make: values.make,
				model: values.model,
				lens: values.lens,
				f: values.f,
				s: values.s,
				iso: values.iso,
				focal: values.focal,
				mode: values.mode,
				country: values.country,
				city: values.city,
				copyright: values.copyright,
				created: values.created,
			},
			config
		);

		// console.log(data.data);
		return handleData(data);
	} catch (error) {
		console.log(error.response.data.error);
		setServerError(error.response.data.error);
		setTimeout(() => {
			setServerError('');
		}, 5000);
	}
};

// PROJECTS
export const PostProject = async ({ values, handleData, setServerError }) => {
	const endpoint = '/api/project/register';
	const config = {
		header: {
			'Content-Type': 'application/json',
		},
	};

	try {
		const data = await Axios.post(
			`${URL}${endpoint}`,
			{
				project: values.project,
				name: values.name,
				filters: values.filters,
				gallery: values.gallery,
				img: values.img,
				pos: values.pos,
				before: values.before,
				after: values.after,
			},
			config
		);

		// console.log(data.data);
		return handleData(data);
	} catch (error) {
		console.log(error.response.data.error);
		setServerError(error.response.data.error);
		setTimeout(() => {
			setServerError('');
		}, 5000);
	}
};

// VIDEOS
export const PostVideo = async ({ values, handleData, setServerError }) => {
	const endpoint = '/api/video/register';
	const config = {
		header: {
			'Content-Type': 'application/json',
		},
	};

	try {
		const data = await Axios.post(
			`${URL}${endpoint}`,
			{
				type: values.type,
				title: values.title,
				link: values.link,
			},
			config
		);

		// console.log(data.data);
		return handleData(data);
	} catch (error) {
		console.log(error.response.data.error);
		setServerError(error.response.data.error);
		setTimeout(() => {
			setServerError('');
		}, 5000);
	}
};
