import React, { useEffect, useState, useContext } from 'react';

// Components
import { GalleryImage } from './GalleryImage';
import { trackWindowScroll } from 'react-lazy-load-image-component';
import { EditPhotoInfo } from '../EditPhotoInfo';

// Context Services
import AuthContextProvider from '../../context/AuthContext';

// Service
import { DeletePhoto } from '../../services/deleteData';

// Icons
import { ReactComponent as Cancel } from '../../assets/icons/modal/cancel.svg';
import { ReactComponent as Info } from '../../assets/icons/modal/info.svg';
import { ReactComponent as Edit } from '../../assets/icons/ui/edit_nc.svg';
import { ReactComponent as Delete } from '../../assets/icons/ui/delete_full_nc.svg';

const GalleryList = ({ data, gallery, scrollPosition, setRefresh }) => {
	const [modal, setModal] = useState(false);
	const [modalImage, setModalImage] = useState('');
	const [modalData, setModalData] = useState([]);
	const [display, setDisplay] = useState(gallery);
	const [infoImg, setInfoImg] = useState(false);
	const [infoEdit, setInfoEdit] = useState(false);
	const [deletePhoto, setDeletePhoto] = useState(false);

	// Services Context
	const { user } = useContext(AuthContextProvider);

	// Prevent page reload
	useEffect(() => {
		window.onbeforeunload = function () {
			return 'You will lost the sequence';
		};
	}, []);

	const handleClose = () => {
		setModal(!modal);
		setInfoImg(false);
		setInfoEdit(false);
		setDeletePhoto(false);
	};

	const handleEdit = () => {
		setInfoImg(false);
		setDeletePhoto(false);
		setInfoEdit(!infoEdit);
	};
	const handleInfo = () => {
		setDeletePhoto(false);
		setInfoEdit(false);
		setInfoImg(!infoImg);
	};
	const handleDelete = () => {
		setInfoImg(false);
		setInfoEdit(false);
		setDeletePhoto(!deletePhoto);
	};

	const deletePhotoDB = () => {
		setDeletePhoto(false);
		setModal(false);
		let id = modalData._id;
		DeletePhoto({ id }).then(setRefresh(true));
		// console.log(id);
	};

	return (
		<>
			<div className="display">
				<label>Display:</label>
				<select onChange={(e) => setDisplay(e.target.value)}>
					<option value="grid">normal</option>
					<option value="flex">square</option>
					<option value="mondrian">mondrian</option>
				</select>
			</div>
			<div className={`gallery-container ${display}`}>
				{data.map((image) => (
					<div className="image-box" key={image._id}>
						<GalleryImage
							image={image}
							scrollPosition={scrollPosition}
							modal={modal}
							setModal={setModal}
							setModalImage={setModalImage}
							setModalData={setModalData}
						/>
					</div>
				))}
			</div>
			{modal && (
				<div className="modal">
					<div className="modal__img">
						<div className="close" onClick={handleClose}>
							<Cancel />
						</div>
						<img src={modalImage} alt={'modal-img'} />
						<div className="info" onClick={handleInfo}>
							<Info />
						</div>
						{user && (
							<>
								<div className="edit" onClick={handleEdit}>
									<Edit />
								</div>
								<div className="delete" onClick={handleDelete}>
									<Delete />
								</div>
							</>
						)}

						{infoImg && (
							<div className="image-info" onClick={handleClose}>
								<p>
									<b>Image:</b>
									{` ${modalData.imagename}`}
								</p>
								<p>
									<b>Camera:</b>
									{` ${modalData.make}`}
								</p>
								<p>
									<b>Model:</b>
									{` ${modalData.model}`}
								</p>
								<p>
									<b>Lens:</b>
									{` ${modalData.lens}`}
								</p>
								<p>
									<b>f:</b>
									{` ${modalData.f}`}
									<b> S:</b>
									{modalData.s > 1
										? modalData.s
										: `1/ ${Math.trunc(1 / modalData.s)}`}
									<b> ISO:</b>
									{` ${modalData.iso}`}
								</p>
								<p>
									<b>Focal:</b>
									{` ${modalData.focal}mm`}
								</p>
								<p>
									<b>Mode</b>
									{`: ${modalData.mode}`}
								</p>
								<p>
									<b>Copyright:</b>
									{` ${modalData.copyright}`}
								</p>
								<p>
									<b>Created:</b>
									{` ${new Date(modalData.created).toDateString()}`}
								</p>
							</div>
						)}
						{infoEdit && (
							<EditPhotoInfo modalData={modalData} setInfoEdit={setInfoEdit} />
						)}
						{deletePhoto && (
							<div className="image-delete">
								<p>
									<b>Image:</b>
									{` ${modalData.imagename}`}
								</p>
								<p>
									<b>ImageID:</b>
									{` ${modalData._id}`}
								</p>
								<p>
									<b>ImageID2:</b>
									{` ${modalData.image.public_id}`}
								</p>
								<button className="btn btn-delete" onClick={deletePhotoDB}>
									Delete
								</button>
							</div>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default trackWindowScroll(GalleryList);
