import React, { useState, useEffect } from 'react';

// Components
import { FileBase64 } from '../components/FileBase64';

export const Photo = ({ handlePhoto, photokey, clearImg, setClearImg }) => {
	const [photo, setPhoto] = useState('');
	const [sizePhoto, setSizePhoto] = useState('');
	const [typePhoto, setTypePhoto] = useState('');
	const [imgError, setImgError] = useState('');

	// Image max size (k)
	const IMAGEsize = 250;

	const handelInput = (base64, size, type, file) => {
		setSizePhoto(size);
		setTypePhoto(type);
		// console.log(base64);

		// Validate type (jpg, jpeg & png) & image size:
		const isNameOfOneImageRegEx = /.(jpe?g|png)$/i;
		const isValidType = isNameOfOneImageRegEx.test(file.name);
		const isValidSize = file.size / 1024 < IMAGEsize;

		if (isValidType && isValidSize) {
			handlePhoto(base64, photokey);
			setPhoto(base64);
		} else {
			setPhoto('');
		}

		if (!isValidType) {
			setImgError(`Invalid format, choose another file`);
			// setTimeout(() => {
			// 	setImgError('');
			// 	setSizePhoto('');
			// 	setTypePhoto('');
			// }, 5000);
		} else {
			// Validate size > 200k
			if (!isValidSize) {
				setImgError(`Invalid size > ${IMAGEsize}k, choose another file`);
				// setTimeout(() => {
				// 	setImgError('');
				// 	setSizePhoto('');
				// 	setTypePhoto('');
				// }, 5000);
			}
		}
	};
	useEffect(() => {
		if (clearImg) {
			setPhoto('');
			setClearImg(false);
		}
	}, [clearImg]);
	return (
		<div className='form-item'>
			<label>
				Photo: <b>{`type: jpg/jpeg & max: ${IMAGEsize}k`}</b>
			</label>
			<div id='type-file'>
				<FileBase64
					type='file'
					multiple={false}
					accept='.jpg, .jpeg, .png'
					onDone={({ base64, size, type, file }) =>
						handelInput(base64, size, type, file)
					}
				/>
			</div>
			{imgError ? (
				<p style={{ color: 'red', width: '330px' }}>{imgError} </p>
			) : (
				<p style={{ color: 'green' }}>
					<b>{`${typePhoto} ${sizePhoto}`}</b>
				</p>
			)}
			<div className='image-container'>
				{photo && (
					<>
						<p>Image preview:</p>
						<img
							className='image-avatar'
							src={photo}
							alt={typePhoto}
							style={{ maxHeight: '300px', objectFit: 'cover' }}
						/>
					</>
				)}
			</div>
		</div>
	);
};
