import React, { useState, useEffect } from 'react';

// Services API
import { GetVideos } from '../services/getData';

const Context = React.createContext({});

export function VideoContextProvider({ children }) {
	const [refresh, setRefresh] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [videoList, setVideoList] = useState([]);

	useEffect(() => {
		// console.log('Get Photos');
		GetVideos({ setVideoList, setLoading, setError });
		setRefresh(false);
	}, [setVideoList, refresh]);

	return (
		<Context.Provider
			value={{
				videoList,
				setVideoList,
				loading,
				setLoading,
				error,
				setError,
				setRefresh,
			}}
		>
			{children}
		</Context.Provider>
	);
}

export default Context;
