export default function validatePhoto(values) {
  let namePattern = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/g;

  // DATE
  //let today = new Date();
  //let year = today.getFullYear();

  let errors = {};

  // Image or Photo
  if (!values.image) {
    errors.image = "Image is required";
  }

  // Image Name
  if (!values.imagename) {
    errors.imagename = "Image name is required";
  }

  // Image size
  if (values.imagesize > 300) {
    errors.imagesize = "Image size is invalid > 300";
  }

  // keywords
  if (!values.keywords) {
    errors.keywords = "Keywords are required";
  } else if (values.keywords.length < 10) {
    errors.keywords = "Keywords lenght is invalid > 10";
  }

  // Country
  if (!values.country) {
    errors.country = "Country is required";
  }

  // City
  if (!values.city) {
    errors.city = "City is required";
  }

  // Copyright
  if (!values.copyright) {
    errors.copyright = "Copyright is required";
  } else if (!namePattern.test(values.copyright)) {
    errors.copyright = "Copyright is invalid, only letters";
  }

  // Created Date
  if (!values.created) {
    errors.created = "Created date is required";
  } else if (values.created == "Invalid Date") {
    errors.created = "Created date is required";
  }

  return errors;
}
