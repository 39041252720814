import React, { useState, useContext } from 'react';

// Hooks
import useForm from '../../hooks/useForm';
import validate from '../../hooks/validateProject';

// Services
import { PostProject } from '../../services/postData';

// Services Context
import ProjectsContextProvider from '../../context/ProjectsContext';

// Components
import { Photo } from '../../components/Photo';

export const ProjectINPUT = ({ photokey, setOpenToast }) => {
	// Context
	const { setRefresh } = useContext(ProjectsContextProvider);

	const initialForm = {
		project: '',
		name: '',
		filters: [],
		gallery: 'mondrian',
		img: '',
		pos: 'center',
		before: '/gallery/',
		after: '/gallery/',
	};

	const [serverError, setServerError] = useState('');
	const [clearImg, setClearImg] = useState(false);

	// Hook
	const { handleChange, handlePhoto, handleSubmit, values, errors, reset } =
		useForm(submit, validate, initialForm);

	function submit() {
		handleUpdate();
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}

	const handleUpdate = async () => {
		PostProject({ values, handleData, setServerError }).then(setRefresh(true));
		setClearImg(true);
		setOpenToast(true);
	};

	const handleData = async (data) => {
		reset();
		setServerError('');
	};

	const handleReset = () => {
		reset();
		setClearImg(true);
	};

	return (
		<form
			className='input-project-container'
			onSubmit={handleSubmit}
			noValidate
		>
			<div className='input-project-img'>
				<div className='item-img'>
					<Photo
						handlePhoto={handlePhoto}
						photokey={photokey}
						clearImg={clearImg}
						setClearImg={setClearImg}
					/>
					<span className='error'>
						{serverError && <p style={{ color: 'red' }}>{serverError}</p>}
					</span>
				</div>
			</div>

			<div className='input-project-data'>
				<div className='item-content'>
					<label>Project: </label>
					<input
						id='title'
						className='Item'
						name='project'
						type='text'
						value={values.project}
						onChange={handleChange}
						placeholder='PROJECT Name'
					/>
				</div>
				<span className='error'>
					{errors.project && <p className='error'>{errors.project}</p>}
				</span>
				<div className='item-content'>
					<label>Name: </label>
					<input
						id='title'
						className='Item'
						name='name'
						type='text'
						value={values.name}
						onChange={handleChange}
						placeholder='TITLE'
					/>
				</div>
				<span className='error'>
					{errors.name && <p className='error'>{errors.name}</p>}
				</span>
				<div className='item-content'>
					<label>Filters: </label>
					<input
						id='title'
						className='Item'
						name='filters'
						type='text'
						value={values.filters}
						onChange={handleChange}
						placeholder='Close, Reflect, Beatles, ...'
					/>
				</div>
				<span className='error'>
					{errors.filters && <p className='error'>{errors.filters}</p>}
				</span>
				<div className='item-content'>
					<label>Gallery: </label>
					<input
						id='title'
						className='Item'
						name='gallery'
						type='text'
						value={values.gallery}
						onChange={handleChange}
					/>
				</div>
				<span className='error'>
					{errors.gallery && <p className='error'>{errors.gallery}</p>}
				</span>

				<div className='item-content'>
					<label>POS: </label>
					<input
						id='title'
						className='Item'
						name='pos'
						type='text'
						value={values.pos}
						onChange={handleChange}
					/>
				</div>
				<span className='error'>
					{errors.pos && <p className='error'>{errors.pos}</p>}
				</span>
				<div className='item-content'>
					<label>Before: </label>
					<input
						id='title'
						className='Item'
						name='before'
						type='text'
						value={values.before}
						onChange={handleChange}
					/>
				</div>
				<span className='error'>
					{errors.before && <p className='error'>{errors.before}</p>}
				</span>
				<div className='item-content'>
					<label>After: </label>
					<input
						id='title'
						className='Item'
						name='after'
						type='text'
						value={values.after}
						onChange={handleChange}
					/>
				</div>
				<span className='error'>
					{errors.after && <p className='error'>{errors.after}</p>}
				</span>

				<div className='input-btn-container'>
					<button className='submit' type='submit'>
						Submit
					</button>
					<button className='reset' onClick={handleReset}>
						Reset
					</button>
				</div>
			</div>
		</form>
	);
};
