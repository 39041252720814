import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

// Components
import { NavBar } from '../components/NavBar';

// Pages
import Home from '../pages/Home';

// Modals
import { Modal } from '../components/Modal';
import UserRegisterForm from '../components/UserRegisterForm';
import EditUserForm from '../components/EditUserForm';

// Services
import AuthContextProvider from '../context/AuthContext';

// JSON Data
import PhotosToJson from '../components/PhotosToJson';

export const Admin = () => {
	const initialForm = {
		folderRoute: './assets/images/',
		imgNameUsed: 'image-',
		nPhotosTotal: 10,
	};

	const [folder, setFolder] = useState(initialForm.folderRoute);
	const [imgName, setImgName] = useState(initialForm.imgNameUsed);
	const [nPhotos, setNPhotos] = useState(initialForm.nPhotosTotal);
	const [values, setValues] = useState(initialForm);
	const [submit, setSubmit] = useState(false);

	// Open states
	const [openUserRegisterForm, setOpenUserRegisterForm] = useState(false);
	const [openEditUserForm, setOpenEditUserForm] = useState(false);

	// Services Context
	const { user, setUser, setUserAcceptCookies } =
		useContext(AuthContextProvider);

	const handleRegister = () => {
		setOpenUserRegisterForm(true);
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	const handleEditRegister = () => {
		setOpenEditUserForm(true);
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	const handleChange = (event) => {
		setSubmit(false);
		const { name, value } = event.target;
		setValues({
			...values,
			[name]: value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log(values);
		let num = parseInt(values.nPhotosTotal, 10);
		console.log(num);
		setFolder(values.folderRoute);
		setImgName(values.imgNameUsed);
		setNPhotos(num);
		setSubmit(true);
	};

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, []);

	if (!user) {
		return <Navigate to='/' element={<Home />} />;
	}

	return (
		<div className='general__container admin'>
			{openUserRegisterForm && (
				<Modal>
					<UserRegisterForm
						setUser={setUser}
						setOpenUserRegisterForm={setOpenUserRegisterForm}
						setUserAcceptCookies={setUserAcceptCookies}
					/>
				</Modal>
			)}

			{openEditUserForm && (
				<Modal>
					<EditUserForm user={user} setOpenEditUserForm={setOpenEditUserForm} />
				</Modal>
			)}

			<header>
				<NavBar menuName='dashboard-admin' />
				<h2 className='title'>Dashboard</h2>
			</header>

			<main>
				<button
					className='btn btn__main'
					onClick={handleRegister}
					style={{ width: '60%', margin: '0.8em auto' }}
				>
					USER Register
				</button>
				<button
					className='btn btn__main'
					onClick={handleEditRegister}
					style={{ width: '60%', margin: '0.8em auto' }}
				>
					Edit User
				</button>
				<Link
					to='/addphotos'
					className='btn btn__main'
					style={{ textAlign: 'center', width: '60%', margin: '0.8em auto' }}
				>
					Add PHOTOS
				</Link>
				<Link
					to='/projects/crud'
					className='btn btn__main'
					style={{ textAlign: 'center', width: '60%', margin: '0.8em auto' }}
				>
					Projects CRUD
				</Link>
				<Link
					to='/videos/crud'
					className='btn btn__main'
					style={{ textAlign: 'center', width: '60%', margin: '0.8em auto' }}
				>
					Videos CRUD
				</Link>
				<h2 className='title'>Get Photos Exif Data</h2>
				<form onSubmit={handleSubmit} className='exif'>
					<label>Read folder route:__</label>
					<input
						type='text'
						id='folder'
						name='folderRoute'
						value={values.folderRoute}
						placeholder={values.folderRoute}
						onChange={handleChange}
					></input>
					<br />
					<br />
					<label>Image name# used: </label>
					<input
						type='text'
						id='imgName'
						name='imgNameUsed'
						value={values.imgNameUsed}
						placeholder={values.imgNameUsed}
						onChange={handleChange}
					></input>

					<br />
					<br />
					<label>Number of photos:__</label>
					<input
						type='number'
						min='1'
						id='number'
						name='nPhotosTotal'
						value={values.nPhotosTotal}
						placeholder={values.nPhotosTotal}
						onChange={handleChange}
					></input>
					<br />
					<br />
					<button className='btn btn-click' type='submit'>
						Submit
					</button>
				</form>

				{submit && (
					<PhotosToJson
						imgFolder={folder}
						imgName={imgName}
						nPhotos={nPhotos}
					/>
				)}
			</main>

			<footer>
				<p>
					<a href='https://vmog.net/' target='_blank' rel='noreferrer'>
						Copyright and web design by © VMOG
					</a>
				</p>
				<p> Liverpool UK 2022 </p>
			</footer>
		</div>
	);
};
