import React from 'react';

export const VideoDATA = ({ video, num, handleForm, handleDelete }) => {
	return (
		<>
			<div className='card-video-data'>
				<p className='number'>
					<b>{num + 1}</b>
				</p>
				<p>
					Type: <b>{video.type}</b>
				</p>
				<p>
					Title: <b>{video.title}</b>
				</p>
				<p>
					Link: <b>{video.link}</b>
				</p>
			</div>
			<div className='buttons-container'>
				<button className='btn' onClick={handleForm}>
					✏️
				</button>
				<button className='btn' onClick={handleDelete}>
					🗑
				</button>
			</div>
		</>
	);
};
