import React, { useState, useEffect } from 'react';

// Services API
import { GetPhotos } from '../services/getData';

const Context = React.createContext({});

export function PhotoContextProvider({ children }) {
	const [refresh, setRefresh] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [photoList, setPhotoList] = useState([]);

	useEffect(() => {
		// console.log('Get Photos');
		GetPhotos({ setPhotoList, setLoading, setError });
		setRefresh(false);
	}, [setPhotoList, refresh]);

	return (
		<Context.Provider
			value={{
				photoList,
				setPhotoList,
				loading,
				setLoading,
				error,
				setError,
				setRefresh,
			}}
		>
			{children}
		</Context.Provider>
	);
}

export default Context;
