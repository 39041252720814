import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

// Components
import { NavBar } from '../components/NavBar';
import { RotatingLines } from 'react-loader-spinner';
import { ScrollBackToTop } from '../components/ScrollBackToTop';
import { Footer } from '../components/Footer';

// Services
import AuthContextProvider from '../context/AuthContext';
import ProjectsContextProvider from '../context/ProjectsContext';
import PhotoContextProvider from '../context/PhotoContext';

// Data
const initialProjects = require('../data/projects.json').projects;

export const Projects = () => {
	// Services Context
	const { user } = useContext(AuthContextProvider);
	const { dataList } = useContext(ProjectsContextProvider);
	const { photoList } = useContext(PhotoContextProvider);

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		// console.log(dataList);
		// console.log(initialProjects);
	}, []);

	return (
		<div className="general__container projects-container ">
			<header>
				<NavBar menuName="projects" photoList={photoList} />
			</header>
			<main>
				<h1>
					PROJECTS: <b>{dataList.length}</b>
				</h1>
				{dataList.length < 10 && (
					<>
						<div className="projects-wrapper">
							{initialProjects.map((pro, i) => {
								return (
									<div
										key={`${pro.project}-${i}`}
										className="project"
										style={{
											backgroundImage: `url(${pro.img.url})`,
											backgroundPositionX: `${pro.pos}`,
										}}
									>
										<Link to={`/gallery/${pro.project}`} className="link">
											<h3>{pro.project}</h3>
										</Link>
									</div>
								);
							})}
						</div>
						<div className="Loading">
							<h2>Loading...</h2>
							<br />
							<RotatingLines
								strokeColor="#007AFF"
								strokeWidth="5"
								animationDuration="0.75"
								width="96"
								visible={true}
							/>
						</div>
					</>
				)}
				<div className="projects-wrapper">
					{dataList.map((pro, i) => {
						return (
							<div
								key={`${pro.project}-${i}`}
								className="project"
								style={{
									backgroundImage: `url(${pro.img.url})`,
									backgroundPositionX: `${pro.pos}`,
								}}
							>
								<Link to={`/gallery/${pro.project}`} className="link">
									<h3>{pro.project}</h3>
								</Link>
							</div>
						);
					})}
				</div>
				<ScrollBackToTop />
			</main>
			<footer id="footer">
				<Footer user={user} />
			</footer>
		</div>
	);
};
