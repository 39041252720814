import React, { useState, useContext } from 'react';

// Hooks
import useForm from '../hooks/useForm';
import validate from '../hooks/validatePhoto';

// Services
import { EditPhoto } from '../services/patchData';
import PhotoContextProvider from '../context/PhotoContext';

export const EditPhotoInfo = ({ modalData, setInfoEdit }) => {
	const initialForm = {
		image: modalData.image,
		imagename: modalData.imagename,
		title: modalData.title,
		keywords: modalData.keywords,
		make: modalData.make,
		model: modalData.model,
		lens: modalData.lens,
		f: modalData.f,
		s: modalData.s,
		iso: modalData.iso,
		focal: modalData.focal,
		mode: modalData.mode,
		country: modalData.country,
		city: modalData.city,
		copyright: modalData.copyright,
		created: modalData.created,
	};
	// console.log(initialForm);
	const [serverError, setServerError] = useState('');

	const { setRefresh } = useContext(PhotoContextProvider);

	// Hook
	const { handleChange, handlePhoto, handleSubmit, values, errors, reset } =
		useForm(submit, validate, initialForm);

	// Function to format the date to DD-MM-YYYY
	const formatDate = (dateString) => {
		const date = new Date(dateString);
		const day = String(date.getDate()).padStart(2, '0');
		const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
		const year = date.getFullYear();
		return `${day}-${month}-${year}`;
	};

	function submit() {
		const valuesEdited = {
			photoId: modalData._id,
			updateData: values,
		};

		handleUpdate(valuesEdited);
	}

	const handleUpdate = async (valuesEdited) => {
		// console.log(valuesEdited);
		EditPhoto({ valuesEdited, setServerError }).then(setRefresh(true));
		setInfoEdit(false);
	};

	return (
		<form className="image-edit" onSubmit={handleSubmit} noValidate>
			<span className="error">
				{serverError && <p style={{ color: 'red' }}>{serverError}</p>}
			</span>
			<div className="item-content">
				<label>Title: </label>
				<input
					id="title"
					className="Item"
					name="title"
					type="text"
					value={values.title}
					onChange={handleChange}
					placeholder="TITLE"
				/>
			</div>
			<span className="error">
				{errors.title && <p className="error">{errors.title}</p>}
			</span>

			<div className="item-content">
				<label>Keywords: </label>
				<textarea
					id="keywords"
					className="Item"
					name="keywords"
					type="text"
					value={values.keywords}
					onChange={handleChange}
					placeholder="KEYWORDS"
					rows="6"
				/>
			</div>
			<span className="error">
				{errors.keywords && <p className="error">{errors.keywords}</p>}
			</span>

			<div className="item-content">
				<label>City: </label>
				<input
					id="city"
					className="Item"
					name="city"
					type="text"
					value={values.city}
					onChange={handleChange}
					placeholder="KEYWORDS"
				/>
			</div>
			<span className="error">
				{errors.city && <p className="error">{errors.city}</p>}
			</span>

			<div className="item-content">
				<label>Country: </label>
				<input
					id="country"
					className="Item"
					name="country"
					type="text"
					value={values.country}
					onChange={handleChange}
					placeholder="KEYWORDS"
				/>
			</div>
			<span className="error">
				{errors.country && <p className="error">{errors.country}</p>}
			</span>
			<div className="item-content">
				<br />
				<span>Date: {formatDate(values.created)}</span>
			</div>

			<button className="submit" type="submit">
				Submit
			</button>
		</form>
	);
};
